export const expandLink = `
	{
		...,
		document {
			"_type": @->_type,
			"slug": @->slug,
			"section": @->section,
		},
		file{
			...,
			asset->{
				...,
				url
			}
		}
	}
`

export const expandButton = `
	{
		...,
		link${expandLink}
	}
`

export const imageWithAlt = `{
	_type,
	asset->{
		_id,
		"c": metadata.palette.dominant.background,
		"r": metadata.dimensions.aspectRatio,
		"w": metadata.dimensions.width,
		"h": metadata.dimensions.height,
	},
	alt
}`

// const imageWithAlt = `{
// 	_type,
// 	asset->{
// 		...
// 	}
// }`

export const expandPortableText = `
	[]{
		...,
		markDefs[]{
			...,
			_type == "link" => {
				...,
				document {
					"_type": @->_type,
					"slug": @->slug
				},
				file{
					...,
					asset->{
						...,
						url
					}
				}
			},
		},
		_type == "button" => {
			...,
			link {
				...,
				document->{
					_type,
					slug,
					section,
				},
				file{
					...,
					asset->{
						...,
						url
					}
				}
			}
		},
	}
`

export const articleThumb = `{
  title,
	_type,
	slug {
		current
	},
	header {
		image${imageWithAlt},
		text${expandPortableText},
	}
}`

export const storyThumb = `{
	title,
	_type,
	slug {
		current
	},
	header {
		image${imageWithAlt},
		text${expandPortableText},
	}
}`

export const productThumb = `{
	_key,
	_type,
	title,
	isDeleted,
	slug {
		current
	},
	header {
		image${imageWithAlt},
		text${expandPortableText},
	}
}`




export const pageHeader = `{
	background{
		value
	},
	headline,
	text${expandPortableText},
	image${imageWithAlt},
}`
