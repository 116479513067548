import { moduleQuery } from "./moduleQuery"

import { 
	expandLink,
	expandButton,
	imageWithAlt,
	expandPortableText,
	articleThumb,
	storyThumb,
	pageHeader,
	productThumb,
} from "./fragments"

const seo = `
	seo{
		...,
		socialImage{
			asset->{
				url
			}
		}
	}
`

export const menuSection = `{
	title,
	subtitle,
	primaryLinks[]{
		_key,
		text,
		link${expandLink},
	},
	popularLinks[]{
		_key,
		text,
		link${expandLink},
	},
	featured[] {
		_type == "article" => ${articleThumb},
		_type == "story" => ${storyThumb},
	},
}`


const global = `
	"global": {
		"siteSettings": *[_type == "siteSettings"] | order(_updatedAt desc) [0] {
			"siteSocialImage": socialImage {
				asset {
					_id
				}
			},
			"siteMetaDescription": metaDescription,
			siteTitle,
			sendFeedbackTo,
			popUpTitle,
			popUpText${expandPortableText},
			popUpButton{
				text,
				link${expandLink}
			},
			showPopUp,
			"smartSearchPages": *[_type == "page" && section == "smart-search"] | order(title asc) {
				_type,
				title,
				slug {
					current
				},
				audience,
				section,
			}
		},
		"moduleSettings": *[_type == "moduleSettings"] | order(_updatedAt desc) [0] {
			helpLine {
				columns[] {
					text${expandPortableText},
					_type,
					heading,
					_key,
				},
				eyebrow,
				headline,
				phoneNumber,
				text,
			},
			smartSearch {
				eyebrow,
				text${expandPortableText},
				headline,
			},
			checklist {
				eyebrow,
				headline,
				text${expandPortableText},
				checklists[]->{
					_type,
					title,
					slug {
						current
					}
				}
			},
			chatbot{
				_key,
				_type,
				include,
				background {
					value
				},
				contentOrder,
				eyebrow,
				headline,
				image${imageWithAlt},
				text${expandPortableText},
			},
			checklistGlobalModule{
				_key,
				_type,
				include,
				eyebrow,
				title,
				mediumText${expandPortableText},
				text${expandPortableText},
				background {
					value
				},
				leftImage${imageWithAlt},
				rightImage${imageWithAlt},
			}
		},
		"sanityFooter": *[_type == "footer"] | order(_updatedAt desc) [0] {
			flags[]${imageWithAlt},
			acknowledgementText${expandPortableText},
			acknowledgementButton{
				text,
				link${expandLink}
			},
			socialTitle,
			socialText${expandPortableText},
			signUpLink{
				text,
				link${expandLink}
			},
			facebookUrl,
			instagramUrl,
			linkedInUrl,
			footerMenu[] {
				title,
				links[]{
					text,
					link${expandLink}
				}
			},
			disclaimer${expandPortableText},
			finePrintMenu[]${expandButton},
		},
		"nav": *[_type == "navigation"] | order(_updatedAt desc) [0] {
			getSupport${menuSection},
			learn${menuSection},
			getInvolved${menuSection},
			about${menuSection},
			headerSlimText${expandPortableText},
			"headerSlimMobile": headerSlimTextMobile${expandPortableText},
			headerScrolledText${expandPortableText},
		},
		"contact": *[_type == "page" && slug.current == "contact"] | order(_updatedAt desc) [0] {
			title
		}
	}
`

const pageQueryInner = `{
	"slug": slug.current,
	title,
	section,
	header${pageHeader},
	associatedChecklist {
		slug {
			current
		}
	},
	introduction {
		headline,
		text${expandPortableText}
	},
	modules[]${moduleQuery},
	showFeedbackForm,
	${seo},
}`

export const indexQuery = `
{
	"page": *[_type == "page" && slug.current == "home"] | order(_updatedAt desc) [0] ${pageQueryInner},
	${global}
}
`

export const chatQuery = `
{
	${global}
}
`

export const pageQuery = `
{
  "page": *[_type == "page" && slug.current == $slug] | order(_updatedAt desc) [0] ${pageQueryInner},
	${global}
}`

export const resultsPageQuery = `
{
  "page": *[_type == "page" && associatedChecklist->slug.current == $associatedSlug && response == $response] | order(_updatedAt desc) [0] ${pageQueryInner},
	${global}
}`

// Posts

const postQueryInner = `{
	"slug": slug.current,
	title,
	section,
	datePublished,
	_type,
	header${pageHeader},
	content${expandPortableText},
	sidebarTitle,
	sidebarPosts[]->{
		_type == "article" => ${articleThumb},
		_type == "story" => ${storyThumb},
	},
	modules[]${moduleQuery},
	related[]->{
		_type == "article" => ${articleThumb},
		_type == "story" => ${storyThumb},
		_type == "volunteerStory" => ${storyThumb},
	},
	relatedStories[]->{
		_type == "article" => ${articleThumb},
		_type == "story" => ${storyThumb},
		_type == "volunteerStory" => ${storyThumb},
	},
	showFeedbackForm,
	${seo},
}`

export const articleQuery = `
{
	"page": *[_type == "article" && slug.current == $slug] | order(_updatedAt desc) [0] ${postQueryInner},
	${global}
}
`

export const storyQuery = `
{
	"page": *[_type == "story" && slug.current == $slug] | order(_updatedAt desc) [0] ${postQueryInner},
	${global}
}
`

export const volunteerQuery = `
{
	"page": *[_type == "volunteerStory" && slug.current == $slug] | order(_updatedAt desc) [0] ${postQueryInner},
	${global}
}
`

// Index Pages

const postIndexQueryInner = `{
	"slug": slug.current,
	title,
	_type,
	header${pageHeader},
	categoryMenu[]->{
		title,
		slug {
			current
		}
	},
	tagMenu[]->{
		title,
		slug {
			current
		},
	},
	${seo}
}`

export const articleIndexQuery = `
{
	"page": *[_type == "postIndex" && slug.current == "articles"] | order(_updatedAt desc) [0] ${postIndexQueryInner},
	${global}
}
`

export const storiesIndexQuery = `
{
	"page": *[_type == "postIndex" && slug.current == "stories"] | order(_updatedAt desc) [0] ${postIndexQueryInner},
	${global}
}
`

export const volunteerIndexQuery = `
{
	"page": *[_type == "postIndex" && slug.current == "volunteer-profiles"] | order(_updatedAt desc) [0] ${postIndexQueryInner},
	${global}
}
`


//  Checklists

export const checklistIndexQuery = `
{
	${global}
}
`

export const checklistQuery = `
{
  "page": *[_type == "dynamicChecklist" && slug.current == $slug] | order(_updatedAt desc) [0] {
		title,
		_type,
		"slug": slug.current,
		header${pageHeader},
		sections[] {
			title,
			subtitle,
			questions[] {
				text,
				subText,
				redFlag,
				answers,
			},
			background {
				value
			},
		},
		requestGender
	},
	${global}
}`


export const sitemapQuery = `
{
  "pages": *[_type == "page"] | order(_updatedAt desc) {
		_type,
		slug {
			current
		}
  },
	${global}
}`

// Shop
export const productQuery = `
{
  "page": *[_type == "product" && slug.current == $slug] | order(_updatedAt desc) [0] {
		_type,
		title,
		"slug": slug.current,
		header${pageHeader},
		store {
			title,
			slug {
				current
			},
			variants[]-> {
				store {
					id,
					price
				},
			},
		},
		externalLink,
		modules[]${moduleQuery},
		${seo}
	},
	${global}
}`

export const groupQuery = `
{
	"allProducts": *[_type == "product" && store.status == "active" && isDeleted != true] | order(datePublished desc)${productThumb},
  "page": *[_type == "group" && slug.current == $slug] | order(_updatedAt desc) [0] {
		_type,
		title,
		"slug": slug.current,
		header${pageHeader},
		selectAllProducts,
		selectedProducts[]->${productThumb},
		${seo}
	},
	${global}
}`

export const query404 = `
{
	${global}
}`

export const query500 = `
{
	${global}
}`

export const searchQuery = `
	{
		"documents": {
			"pages": *[_type == "page" && section != "checklist-results"] | order(_updatedAt desc) {
				slug,
				_type,
				title,
				section,
				"headline": header.headline,
				"headerText": pt::text(header.text),
				introduction{
					text${expandPortableText},
					headline,
				},
				"id": _id,
				"metaDescription" : seo.metaDescription,
			},
			"articles": *[_type == "article"] | order(_updatedAt desc) {
				slug,
				_type,
				title,
				"id": _id,
				header{
					text${expandPortableText},
					headline
				},
				"metaDescription" : seo.metaDescription,
			},
			"stories": *[_type == "story"] | order(_updatedAt desc) {
				slug,
				_type,
				title,
				"id": _id,
				"headline": header.headline,
				"headerText": pt::text(header.text),
				"metaDescription" : seo.metaDescription,
			}
		},
		${global}
	}
`


export const getPaths = type => `
*[_type == "${type}" && defined(slug.current)][].slug.current
`

// used in preview
export const documentBySlugQuery = `
*[_type == $_type && slug.current == $slug][0] {
	_type,
  slug,
}
`

export const getPages = () => `
*[_type == "page" && defined(slug.current) && section != "checklist-results"  && slug.current != "home"]{
	slug,
	section,
	_type,
	audience
}
`

export const getChecklistResultsPages = () => `
*[_type == "page" && defined(slug.current) && section == "checklist-results"]{
	slug,
	_type,
	associatedChecklist->{
		slug
	},
	response
}
`

