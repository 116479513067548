import { 
	expandLink,
	expandButton,
	imageWithAlt,
	expandPortableText,
	articleThumb,
	storyThumb,
	pageHeader,
} from "./fragments"

const simpleModule = `{
	_key,
	_type,
	include
}`

export const moduleQuery = `
	{
		_type == "articleCollection" => {
			_key,
			_type,
			include,
			eyebrow,
			title,
			population,
			posts[]->${articleThumb},
			background {
				value
			},
			categoryOrTag{
				_type == "category" => {
					_type,
					title,
					slug {
						current
					}
				},
				_type == "tag" => {
					_type,
					title,
					slug {
						current
					}
				}
			}
		},

		_type == "storyCollection" => {
			_key,
			_type,
			include,
			eyebrow,
			title,
			population,
			storyType,
			posts[]->${storyThumb},
			profiles[]->${storyThumb},
			background {
				value
			},
			categoryOrTag{
				_type == "category" => {
					_type,
					title,
					slug {
						current
					}
				},
				_type == "tag" => {
					_type,
					title,
					slug {
						current
					}
				}
			}
		},

		_type == "productCollection" => {
			_key,
			_type,
			include,
			eyebrow,
			title,
			posts[]->${articleThumb},
			background {
				value
			}
		},

		_type == "faqBlock" => {
			_key,
			_type,
			include,
			title,
			noNumbers,
			text${expandPortableText},
			questions[] {
				question${expandPortableText},
				answer${expandPortableText},
			}
		},

		_type == "logoGrid" => {
			_key,
			_type,
			include,
			title,
			text${expandPortableText},
			logos {
				image${imageWithAlt},
				title,
				link${expandLink},
			}
		},

		_type == "centeredText" => {
			_key,
			_type,
			include,
			eyebrow,
			title,
			mediumText${expandPortableText},
			text${expandPortableText},
			background {
				value
			},
			leftImage${imageWithAlt},
			rightImage${imageWithAlt},
		},

		_type == "campaign" => {
			_key,
			_type,
			include,
			eyebrow,
			title,
			mediumText${expandPortableText},
			text${expandPortableText},
			background {
				value
			},
			leftImage${imageWithAlt},
			rightImage${imageWithAlt},
		},

		_type == "fileCollection" => {
			_key,
			_type,
			include,
			title,
			files[]{
				title,
				file{
					asset->{
						url
					}
				},
				cta,
				background {
					value
				}
			}
		},

		_type == "textImage" => {
			_key,
			_type,
			include,
			background {
				value
			},
			contentOrder,
			eyebrow,
			headline,
			image${imageWithAlt},
			text${expandPortableText},
		},

		_type == "smartSearch" => {
			_key,
			_type,
			include,
			overrideGlobalContent,
			content {
				_key,
				_type,
				eyebrow,
				headline,
				text${expandPortableText}
			}
		},

		_type == "quickLinks" => {
			_key,
			_type,
			title,
			include,
			links[]{
				image${imageWithAlt},
				title,
				link${expandLink}
			}
		},

		_type == "team" => {
			_key,
			_type,
			include,
			title,
			text${expandPortableText},
			members[]{
				name,
				role,
				image${imageWithAlt},
				text${expandPortableText},
				linkText,
				link,
			}
		},

		_type == "freeText" => {
			_key,
			_type,
			include,
			eyebrow,
			text${expandPortableText},
			background {
				value
			}
		},

		_type == "video" => {
			_key,
			_type,
			include,
			eyebrow,
			title,
			text${expandPortableText},
			youTubeUrl,
		},

		_type == "podcast" => {
			_key,
			_type,
			include,
			eyebrow,
			title,
			text${expandPortableText},
			spotifyURL,
		},

		_type == "contactForm" => {
			_key,
			_type,
			include,
			title,
			text${expandPortableText},
			fields[] {
				_type == "textField" => {
					_key,
					_type,
					title,
					placeholder,
				},
				_type == "emailField" => {
					_key,
					_type,
					title,
					placeholder,
				},
				_type == "textArea" => {
					_key,
					_type,
					title,
					placeholder,
				},
				_type == "selectField" => {
					_key,
					_type,
					title,
					placeholder,
					options,
				}
			},
			submitButtonText,
			successMessage,
			errorMessage,
			sendTo
		},

		_type == "formAssemblyEmbed" => {
			_key,
			_type,
			include,
			title,
			formLink,
		},

		_type == "textColumns" => {
			__key,
			_type,
			include,
			title,
			columns[] {
				addLine,
				text${expandPortableText}
			}
		},

		_type == "quote" => {
			_key,
			_type,
			quote,
			attribution,
		},

		_type == "embed" => {
			_key,
			_type,
			embedCode,
		},

		_type == "helpLine" => ${simpleModule},
		_type == "chatBotModule" => ${simpleModule},
		_type == "checklist" => ${simpleModule},
		_type == "checklistModule" => ${simpleModule},
		_type == "embed" => ${simpleModule},
	}
`